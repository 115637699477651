<template>
  <keep-alive>
    <v-row>
      <v-col cols="12" class="px-2 py-0" v-if="!geburtsdatum">
        <v-dialog
          ref="geburtsdatumref"
          v-model="geburtsdatumdialog"
          :return-value.sync="datum"
          persistent
          width="290px"
          content-class="rounded-xl"
          color="dark"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="parse_datum(datum)"
              label="Geburtsdatum (optional)"
              filled
              rounded
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-card dark class="rounded-xl">
            <v-toolbar :color="template.colors.primary">
              <v-toolbar-title>
                Geburtsdatum
              </v-toolbar-title>
            </v-toolbar>
            <v-row justify="center">
              <v-col cols="12">
                <v-date-picker
                  locale="de"
                  v-model="datum"
                  class="rounded-xl"
                  content-class="rounded-xl"
                  :active-picker.sync="activePicker"
                  :max="
                    new Date(
                      Date.now() - new Date().getTimezoneOffset() * 60000,
                    )
                      .toISOString()
                      .substr(0, 10)
                  "
                  min="1950-01-01"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    rounded
                    :color="template.colors.primary"
                    @click="geburtsdatumdialog = false"
                  >
                    Abbrechen
                  </v-btn>
                  <v-btn
                    text
                    rounded
                    :color="template.colors.primary"
                    :style="`color: ${template.colors.inline_primary_text};`"
                    @click="
                      $refs.geburtsdatumref.save(datum)
                      change()
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="12" class="px-2 py-0" v-if="testpflicht_required">
        <v-checkbox
          rounded
          v-model="ggg.getestet"
          :label="
            zuschauer
              ? 'Ich habe einen Nachweis über einen negativen COVID-19 Test (Antigen-Test: Nicht älter als 24h / PCR-Test: Nicht älter als 48h)'
              : 'Person hat einen Nachweis über einen negativen COVID-19 Test (Antigen-Test: Nicht älter als 24h / PCR-Test: Nicht älter als 48h)'
          "
          @change="
            ggg.genesen = false
            ggg.geimpft = false
          "
          @click="change()"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" class="px-2 py-0" v-if="gg_regel">
        <v-checkbox
          rounded
          v-model="ggg.geimpft"
          :label="
            zuschauer
              ? 'Ich bin vollständig geimpft (und die letzte Impfung liegt mindestens 14 Tage zurück)'
              : 'Person ist vollständig geimpft (und die letzte Impfung liegt mindestens 14 Tage zurück)'
          "
          @click="
            ggg.genesen = false
            ggg.getestet = false
            change()
          "
        ></v-checkbox>
      </v-col>
      <v-col cols="12" class="px-2 py-0" v-if="gg_regel">
        <v-checkbox
          rounded
          v-model="ggg.genesen"
          :label="
            zuschauer
              ? 'Ich bin genesen (nicht länger als 6 Monate)'
              : 'Person ist genesen (nicht länger als 6 Monate)'
          "
          @change="
            ggg.getestet = false
            ggg.geimpft = false
          "
          @click="change()"
        ></v-checkbox>
      </v-col>
      <v-col
        cols="12"
        class="px-2 py-0"
        v-if="
          gg_regel &&
          veranstaltung.data &&
          spiele.includes(veranstaltung.data.art) &&
          pcrenabled
        "
      >
        <v-checkbox
          rounded
          v-model="ggg.getestet"
          :label="
            zuschauer
              ? 'Ich habe einen Nachweis über einen negativen PCR-Test, der nicht älter als 48 Stunden ist.'
              : 'Person hat einen Nachweis über einen negativen PCR-Test, der nicht älter als 48 Stunden ist.'
          "
          @change="
            ggg.genesen = false
            ggg.geimpft = false
          "
          @click="change()"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" class="px-2 py-0" v-if="ggplus_regel">
        <v-checkbox
          rounded
          v-model="ggg.geimpft"
          :label="
            zuschauer
              ? 'Ich bin vollständig geimpft und negativ auf COVID-19 getestet'
              : 'Person ist vollständig geimpft und negativ auf COVID-19 getestet'
          "
          @change="
            ggg.genesen = false
            ggg.getestet = ggg.geimpft
          "
          @click="change()"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" class="px-2 py-0" v-if="ggplus_regel">
        <v-checkbox
          rounded
          v-model="ggg.genesen"
          :label="
            zuschauer
              ? 'Ich bin genesen und negativ auf COVID-19 getestet'
              : 'Person ist genesen und negativ auf COVID-19 getestet'
          "
          @change="
            ggg.getestet = ggg.genesen
            ggg.geimpft = false
          "
          @click="change()"
        ></v-checkbox>
      </v-col>
    </v-row>
  </keep-alive>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'GGG Switches',
  props: {
    geimpft: Boolean,
    genesen: Boolean,
    getestet: Boolean,
    geburtsdatum: String,
    pcr: Boolean,
    public: Boolean,
  },
  data() {
    return {
      ggg: {
        genesen: this.genesen || false,
        getestet: this.getestet || false,
        geimpft: this.geimpft || false,
      },
      spiele: [
        'Auswärtsspiel',
        'Meisterschaftsspiel',
        'Freundschaftsspiel',
        'Pokalspiel',
      ],
      geburtsdatumdialog: false,
      datum: this.geburtsdatum || '',
      pcrenabled: this.pcr || false,
      zuschauer: this.public || false,
    }
  },
  watch: {
    ggg: (data) => {
      this.change()
    },
    datum: (data) => {
      this.change()
    },
  },
  computed: {
    ...mapGetters({
      user: 'user',
      team: 'team',
      publicdata: 'publicdata',
      verein: 'verein',
      veranstaltung: 'veranstaltung',
      template: 'template',
    }),
    check_ggg_regeln() {
      if (this.gg_regel) {
        if (this.ggg.geimpft || this.ggg.genesen || this.ggg.getestet) {
          return false
        }
      } else if (this.ggplus_regel) {
        if ((this.ggg.geimpft || this.ggg.genesen) && this.ggg.getestet) {
          return false
        }
      } else if (this.testpflicht_required) {
        if (this.ggg.geimpft || this.ggg.genesen || this.ggg.getestet) {
          return false
        }
      } else {
        return false
      }
      return true
    },
    testpflicht_required() {
      if (this.publicdata.verein && this.publicdata.verein.hygienekonzept) {
        if (this.publicdata.verein.hygienekonzept.testpflicht) {
          if (!this.publicdata.verein.hygienekonzept.gg_regel) {
            return true
          }
        }
      }
      if (this.verein && this.verein.hygienekonzept) {
        if (this.verein.hygienekonzept.testpflicht) {
          if (!this.verein.hygienekonzept.gg_regel) {
            return true
          }
        }
      }
      return false
    },
    gg_regel() {
      if (this.publicdata.verein && this.publicdata.verein.hygienekonzept) {
        if (this.publicdata.verein.hygienekonzept.gg_regel && this.alter) {
          return true
        }
        if (this.publicdata.verein.hygienekonzept.testpflicht) {
          return true
        }
      }
      if (this.verein && this.verein.hygienekonzept) {
        if (this.verein.hygienekonzept.gg_regel && this.alter) {
          return true
        }
        if (this.verein.hygienekonzept.testpflicht) {
          return true
        }
      }
      return false
    },
    ggplus_regel() {
      if (
        this.publicdata.verein.data &&
        this.publicdata.verein.hygienekonzept
      ) {
        if (this.publicdata.verein.hygienekonzept.ggplus_regel) {
          return true
        }
      }
      if (this.verein.data && this.verein.hygienekonzept) {
        if (this.verein.hygienekonzept.ggplus_regel) {
          return true
        }
      }
      return false
    },
    alter() {
      if (this.datum) {
        var req = new Date()
        req.setFullYear(req.getFullYear() - 16)
        var alter = new Zeit(this.datum, '00:00:00')
        if (req.getTime() <= alter.getTimestamp()) {
          return false
        }
      }
      return true
    },
  },
  mounted() {
    if (!this.check_ggg_regeln) {
      this.$emit('valid', true)
    }
  },
  methods: {
    parse_datum(datum) {
      if (datum) {
        var d = datum.split('-')
        return d[2] + '.' + d[1] + '.' + d[0]
      } else {
        return datum
      }
    },
    valid() {
      this.$emit('valid', !this.check_ggg_regeln)
    },
    change() {
      if (!this.alter) {
        this.ggg.getestet = true
      }
      this.valid()
      this.$emit('change', {
        ggg: this.ggg,
        geburtsdatum: this.datum,
      })
    },
  },
}
</script>
