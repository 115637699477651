var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('keep-alive',[_c('v-row',[(!_vm.geburtsdatum)?_c('v-col',{staticClass:"px-2 py-0",attrs:{"cols":"12"}},[_c('v-dialog',{ref:"geburtsdatumref",attrs:{"return-value":_vm.datum,"persistent":"","width":"290px","content-class":"rounded-xl","color":"dark"},on:{"update:returnValue":function($event){_vm.datum=$event},"update:return-value":function($event){_vm.datum=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.parse_datum(_vm.datum),"label":"Geburtsdatum (optional)","filled":"","rounded":"","prepend-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}],null,false,2225969447),model:{value:(_vm.geburtsdatumdialog),callback:function ($$v) {_vm.geburtsdatumdialog=$$v},expression:"geburtsdatumdialog"}},[_c('v-card',{staticClass:"rounded-xl",attrs:{"dark":""}},[_c('v-toolbar',{attrs:{"color":_vm.template.colors.primary}},[_c('v-toolbar-title',[_vm._v(" Geburtsdatum ")])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-date-picker',{staticClass:"rounded-xl",attrs:{"locale":"de","content-class":"rounded-xl","active-picker":_vm.activePicker,"max":new Date(
                    Date.now() - new Date().getTimezoneOffset() * 60000
                  )
                    .toISOString()
                    .substr(0, 10),"min":"1950-01-01"},on:{"update:activePicker":function($event){_vm.activePicker=$event},"update:active-picker":function($event){_vm.activePicker=$event}},model:{value:(_vm.datum),callback:function ($$v) {_vm.datum=$$v},expression:"datum"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","rounded":"","color":_vm.template.colors.primary},on:{"click":function($event){_vm.geburtsdatumdialog = false}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{style:(("color: " + (_vm.template.colors.inline_primary_text) + ";")),attrs:{"text":"","rounded":"","color":_vm.template.colors.primary},on:{"click":function($event){_vm.$refs.geburtsdatumref.save(_vm.datum)
                    _vm.change()}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1)],1):_vm._e(),(_vm.testpflicht_required)?_c('v-col',{staticClass:"px-2 py-0",attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"rounded":"","label":_vm.zuschauer
            ? 'Ich habe einen Nachweis über einen negativen COVID-19 Test (Antigen-Test: Nicht älter als 24h / PCR-Test: Nicht älter als 48h)'
            : 'Person hat einen Nachweis über einen negativen COVID-19 Test (Antigen-Test: Nicht älter als 24h / PCR-Test: Nicht älter als 48h)'},on:{"change":function($event){_vm.ggg.genesen = false
          _vm.ggg.geimpft = false},"click":function($event){return _vm.change()}},model:{value:(_vm.ggg.getestet),callback:function ($$v) {_vm.$set(_vm.ggg, "getestet", $$v)},expression:"ggg.getestet"}})],1):_vm._e(),(_vm.gg_regel)?_c('v-col',{staticClass:"px-2 py-0",attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"rounded":"","label":_vm.zuschauer
            ? 'Ich bin vollständig geimpft (und die letzte Impfung liegt mindestens 14 Tage zurück)'
            : 'Person ist vollständig geimpft (und die letzte Impfung liegt mindestens 14 Tage zurück)'},on:{"click":function($event){_vm.ggg.genesen = false
          _vm.ggg.getestet = false
          _vm.change()}},model:{value:(_vm.ggg.geimpft),callback:function ($$v) {_vm.$set(_vm.ggg, "geimpft", $$v)},expression:"ggg.geimpft"}})],1):_vm._e(),(_vm.gg_regel)?_c('v-col',{staticClass:"px-2 py-0",attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"rounded":"","label":_vm.zuschauer
            ? 'Ich bin genesen (nicht länger als 6 Monate)'
            : 'Person ist genesen (nicht länger als 6 Monate)'},on:{"change":function($event){_vm.ggg.getestet = false
          _vm.ggg.geimpft = false},"click":function($event){return _vm.change()}},model:{value:(_vm.ggg.genesen),callback:function ($$v) {_vm.$set(_vm.ggg, "genesen", $$v)},expression:"ggg.genesen"}})],1):_vm._e(),(
        _vm.gg_regel &&
        _vm.veranstaltung.data &&
        _vm.spiele.includes(_vm.veranstaltung.data.art) &&
        _vm.pcrenabled
      )?_c('v-col',{staticClass:"px-2 py-0",attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"rounded":"","label":_vm.zuschauer
            ? 'Ich habe einen Nachweis über einen negativen PCR-Test, der nicht älter als 48 Stunden ist.'
            : 'Person hat einen Nachweis über einen negativen PCR-Test, der nicht älter als 48 Stunden ist.'},on:{"change":function($event){_vm.ggg.genesen = false
          _vm.ggg.geimpft = false},"click":function($event){return _vm.change()}},model:{value:(_vm.ggg.getestet),callback:function ($$v) {_vm.$set(_vm.ggg, "getestet", $$v)},expression:"ggg.getestet"}})],1):_vm._e(),(_vm.ggplus_regel)?_c('v-col',{staticClass:"px-2 py-0",attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"rounded":"","label":_vm.zuschauer
            ? 'Ich bin vollständig geimpft und negativ auf COVID-19 getestet'
            : 'Person ist vollständig geimpft und negativ auf COVID-19 getestet'},on:{"change":function($event){_vm.ggg.genesen = false
          _vm.ggg.getestet = _vm.ggg.geimpft},"click":function($event){return _vm.change()}},model:{value:(_vm.ggg.geimpft),callback:function ($$v) {_vm.$set(_vm.ggg, "geimpft", $$v)},expression:"ggg.geimpft"}})],1):_vm._e(),(_vm.ggplus_regel)?_c('v-col',{staticClass:"px-2 py-0",attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"rounded":"","label":_vm.zuschauer
            ? 'Ich bin genesen und negativ auf COVID-19 getestet'
            : 'Person ist genesen und negativ auf COVID-19 getestet'},on:{"change":function($event){_vm.ggg.getestet = _vm.ggg.genesen
          _vm.ggg.geimpft = false},"click":function($event){return _vm.change()}},model:{value:(_vm.ggg.genesen),callback:function ($$v) {_vm.$set(_vm.ggg, "genesen", $$v)},expression:"ggg.genesen"}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }