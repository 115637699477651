<template>
  <div>
    <v-row justify="center" class="mt-4" v-if="publicdata.verein">
      <v-col cols="auto" class="text-center">
        <v-img width="50px" :src="publicdata.verein.verein.logo" />
      </v-col>
      <v-col cols="auto" align-self="center">
        <h2 class="white--text">{{ publicdata.verein.verein.name }}</h2>
      </v-col>
      <v-col cols="12" class="text-center mb-0" v-if="!zuschauerdaten">
        <v-divider class="mt-2 mb-4"></v-divider>
        <h4>SCHRITT 1</h4>
        <h2>PERSONENERFASSUNG</h2>
        <h4 class="font-weight-bold text-uppercase">
          Bitte gebe deine Kontaktdaten ein:
        </h4>
        <v-divider class="mt-4"></v-divider>
      </v-col>
      <v-col cols="12" v-if="!zuschauerdaten">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row justify="center">
            <v-col cols="11" class="py-0">
              <v-text-field
                filled
                rounded
                label="Vorname"
                :rules="[rules.required, rules.name]"
                v-model="v.vorname"
              ></v-text-field>
            </v-col>
            <v-col cols="11" class="py-0">
              <v-text-field
                filled
                rounded
                label="Nachname"
                :rules="[rules.required, rules.name]"
                v-model="v.name"
              ></v-text-field>
            </v-col>
            <v-col cols="11" class="py-0">
              <v-text-field
                filled
                rounded
                label="Strasse + Nr."
                :rules="[rules.required, rules.street, rules.streetnr]"
                v-model="v.adresse"
              ></v-text-field>
            </v-col>
            <v-col cols="5" class="py-0">
              <v-text-field
                filled
                rounded
                label="Postleitzahl"
                :rules="[rules.required, rules.plz]"
                v-model="v.plz"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-text-field
                filled
                rounded
                label="Ort"
                :rules="[rules.required, rules.name]"
                v-model="v.ort"
              ></v-text-field>
            </v-col>
            <v-col cols="11" class="py-0">
              <v-text-field
                filled
                rounded
                label="Telefon"
                :rules="[rules.required, rules.telefon]"
                v-model="v.telefon"
              ></v-text-field>
            </v-col>
            <v-col cols="11" class="py-0" v-if="template.var.nationen">
              <v-select
                filled
                rounded
                label="Nation"
                :rules="[rules.required]"
                :items="template.var.nationen"
                v-model="v.nation"
                @change="change_nation()"
              ></v-select>
            </v-col>
            <v-col cols="11" class="py-0" v-if="aktivitaeten.length > 0">
              <v-select
                filled
                rounded
                label="Aktivität"
                :rules="[rules.required]"
                :items="aktivitaeten"
                v-model="v.aktivitaet"
              ></v-select>
            </v-col>
            <v-col cols="11" class="py-0 mb-4">
              <v-divider class="my-3"></v-divider>
              <span
                class="font-weight-light white--text"
                v-if="
                  template.var.verband == 'FLVW' ||
                  template.var.verband == 'FVM'
                "
              >
                Einverständniserklärung zur Erhebung personenbezogener Daten
                <br />
                <br />
                Der Verein ist im Rahmen der Coronaschutzverordnung
                (CoronaSchVO) des Landes Nordrhein-Westfalens (NRW) während der
                Corona-Pandemie zur Erhebung von personenbezogenen Kontaktdaten
                und Aufenthaltszeiträumen verpflichtet. Die Erhebung der Daten
                erfolgt gemäß § 4a Abs. 1 i.V.m. Abs. 2 CoronaSchVO NRW in der
                derzeit gültigen Fassung.
              </span>
              <span
                class="font-weight-light white--text"
                v-if="template.var.verband == 'BFV'"
              >
                Einverständniserklärung zur Erhebung personenbezogener Daten
                <br />
                <br />
                Der Verein ist im Rahmen der
                SARS-CoV-2-Infektionsschutzmaßnahmenverordnung des Landes Berlin
                während der Corona-Pandemie zur Erhebung von personenbezogenen
                Kontaktdaten und Aufenthaltszeiträumen verpflichtet. Die
                Erhebung der Daten erfolgt gemäß §4 Abs. 1-5
                SARS-CoV-2-Infektionsschutzmaßnahmenverordnung Berlin in der
                derzeit gültigen Fassung.
              </span>
            </v-col>
            <v-col cols="11" class="py-0">
              <v-checkbox
                v-model="checkbox"
                :label="
                  template.var.verband == 'BFV'
                    ? 'Hiermit bin ich mit der Speicherung meiner Daten im Rahmen der SARS-CoV-2-Infektionsschutzmaßnahmenverordnung einverstanden.'
                    : 'Hiermit bin ich mit der Speicherung meiner Daten im Rahmen der Corona-Schutzverordnung einverstanden.'
                "
              ></v-checkbox>
            </v-col>
            <v-col cols="11" class="py-0">
              <v-btn
                :color="template.colors.primary"
                :style="`color: ${template.colors.inline_primary_text};`"
                block
                rounded
                large
                :disabled="!checkbox"
                @click="
                  save_zuschauerdaten()
                  scrollTop()
                "
              >
                WEITER
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col cols="12" class="text-center mb-0" v-if="zuschauerdaten && !error">
        <v-divider class="mt-2 mb-4"></v-divider>
        <h4 v-if="!user">SCHRITT 2</h4>
        <h2>VERANSTALTUNG WÄHLEN</h2>
        <h4 class="font-weight-bold text-uppercase">
          Bitte wähle die Veranstaltung(en) aus, die du besuchen möchtest:
        </h4>
        <v-divider class="mt-4"></v-divider>
      </v-col>
      <v-col
        cols="12"
        class="text-center mt-0"
        v-if="publicdata.veranstaltungen && zuschauerdaten && !error"
      >
        <v-row justify="center" class="my-5">
          <v-col cols="12" class="text-center">
            <h2 class="font-weight-light">
              Deine Ankunftszeit:
              <span class="font-weight-bold">
                {{ ankunftszeit_changed ? ankunftszeit : timer.uhrzeit }} Uhr
              </span>
            </h2>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-dialog
              ref="dialog"
              v-model="modal2"
              :return-value.sync="ankunftszeit"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  rounded
                  :color="template.colors.primary"
                  :style="`color: ${template.colors.inline_primary_text};`"
                  v-on="on"
                  v-bind="attrs"
                >
                  <v-icon class="mr-2">mdi-access-time</v-icon>
                  Ankunftszeit ändern
                </v-btn>
              </template>
              <v-time-picker
                v-if="modal2"
                v-model="ankunftszeit"
                full-width
                format="24hr"
                :max="aktuellezeit"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  :color="template.colors.success"
                  :style="`color: ${template.colors.success_text};`"
                  @click="
                    ankunftszeit_changed = false
                    modal2 = false
                  "
                >
                  Jetzt
                </v-btn>
                <v-btn
                  text
                  :color="template.colors.primary"
                  :style="`color: ${template.colors.inline_primary_text};`"
                  @click="modal2 = false"
                >
                  Abbrechen
                </v-btn>
                <v-btn
                  text
                  :color="template.colors.primary"
                  :style="`color: ${template.colors.inline_primary_text};`"
                  @click="
                    $refs.dialog.save(ankunftszeit)
                    ankunftszeit_changed = true
                  "
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-col>
        </v-row>
        <v-card
          class="my-3 py-0"
          rounded="xl"
          :color="
            selection.includes(v.id) ? 'success' : template.colors.secondary
          "
          :style="
            selection.includes(v.id)
              ? ''
              : `color: ${template.colors.inline_secondary_text};`
          "
          v-for="(v, index) in publicdata.veranstaltungen"
          :key="index"
          :disabled="v.anwesende_personen >= v.maxzuschauer"
          @click="select(v.id, v.art)"
        >
          <v-row justify="center" class="py-0 my-0">
            <v-col cols="auto" class="ml-2 pb-0" align-self="center">
              <v-icon
                large
                :color="
                  selection.includes(v.id)
                    ? 'white'
                    : template.colors.inline_secondary_text
                "
              >
                {{
                  selection.includes(v.id)
                    ? 'mdi-check-bold'
                    : v.art == 'Training'
                    ? 'mdi-strategy'
                    : v.art == 'Wettkampf'
                    ? 'mdi-podium'
                    : v.art == 'Freundschaftsspiel'
                    ? 'mdi-soccer'
                    : v.art == 'Meisterschaftsspiel'
                    ? 'mdi-soccer-field'
                    : v.art == 'Versammlung'
                    ? 'mdi-calendar-star'
                    : 'mdi-trophy-variant'
                }}
              </v-icon>
            </v-col>

            <v-col align-self="center" class="pb-0">
              <h4>
                {{ v.art }}:
                <br />
                {{
                  v.art != 'Wettkampf' && v.art != 'Versammlung'
                    ? v.mannschaft
                    : v.name
                }}{{
                  v.art != 'Training' &&
                  v.art != 'Wettkampf' &&
                  v.art != 'Versammlung'
                    ? '  vs  ' + v.gastmannschaft
                    : ''
                }}
              </h4>
              <p v-if="v.beginn.datum == timer.datum">
                Beginn: {{ v.beginn.uhrzeit }} Uhr
              </p>
              <p
                v-if="
                  v.beginn.datum < timer.datum && v.ende.datum > timer.datum
                "
              >
                <small>GANZTÄGIG</small>
              </p>
              <p
                v-if="
                  v.beginn.datum < timer.datum && v.ende.datum == timer.datum
                "
              >
                Ende: {{ v.ende.uhrzeit }}
              </p>
            </v-col>
          </v-row>
        </v-card>

        <v-card
          :class="selection.includes('andere') ? 'mt-5' : 'my-5'"
          rounded="xl"
          :color="
            selection.includes('andere') ? 'success' : template.colors.secondary
          "
          :style="
            selection.includes('andere')
              ? ''
              : `color: ${template.colors.inline_secondary_text};`
          "
        >
          <v-row
            justify="center"
            class="py-0 my-0"
            link
            @click="select('andere')"
          >
            <v-col cols="auto" class="ml-2" align-self="center">
              <v-icon
                large
                :color="
                  selection.includes('andere')
                    ? 'white'
                    : template.colors.inline_secondary_text
                "
              >
                {{
                  !selection.includes('andere')
                    ? 'mdi-checkbox-blank-circle-outline'
                    : 'mdi-checkbox-marked-circle-outline'
                }}
              </v-icon>
            </v-col>

            <v-col align-self="center">
              <h4>
                Andere Veranstaltung des Vereins
              </h4>
            </v-col>
          </v-row>
          <v-divider
            class="my-2"
            v-if="selection.includes('andere')"
          ></v-divider>
          <v-row justify="center" v-if="selection.includes('andere')">
            <v-col cols="12" md="10" lg="8">
              <p class="font-weight-bold">
                Wie lange wird dein Aufenthalt dauern?
              </p>
              <v-select
                filled
                rounded
                label="Auswählen"
                item-text="label"
                item-value="time"
                :items="zeiten"
                v-model="zeitwahl"
              ></v-select>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          class="mb-5"
          color="success"
          v-if="selection.includes('andere')"
        ></v-card>
      </v-col>
      <v-col cols="12" v-if="!error">
        <v-divider class="my-4"></v-divider>
      </v-col>
      <v-col cols="12" class="text-center" v-if="!error">
        <v-chip
          v-for="(p, index) in weitere_personen"
          :key="index"
          class="ma-2"
          color="grey lighten-1"
          text-color="#111"
          large
          close
          close-icon="mdi-close"
          @click:close="delete_person(index)"
        >
          <v-avatar left>
            <v-icon>mdi-account-circle</v-icon>
          </v-avatar>
          {{ p.vorname ? p.vorname + ' ' + p.nachname : p }}
        </v-chip>
      </v-col>
      <v-col
        cols="12"
        class="text-center"
        v-if="
          (rolle != 'Schiedsrichter' ||
            (rolle == 'Schiedsrichter' &&
              (!schiedsrichter ||
                selected_types.includes('Meisterschaftsspiel') ||
                selected_types.includes('Freundschaftsspiel') ||
                selected_types.includes('Pokalspiel')))) &&
          !error
        "
      >
        <v-btn
          color="success"
          :outlined="add_person"
          rounded
          @click="add_person = !add_person"
        >
          <v-icon v-if="!add_person">mdi-plus</v-icon>
          {{ add_person ? 'Abbrechen' : 'Weitere Person aus Haushalt' }}
        </v-btn>
      </v-col>
      <v-expand-transition>
        <v-col cols="12" md="8" class="text-center" v-if="add_person">
          <v-row justify="center">
            <v-col cols="5" class="pr-0">
              <v-text-field
                label="Vorname"
                filled
                rounded
                class="rounded-l-xl rounded-r-0"
                v-model="new_person.vorname"
              ></v-text-field>
            </v-col>
            <v-col cols="7" class="pl-0">
              <v-text-field
                label="Nachname"
                filled
                rounded
                class="rounded-r-xl rounded-l-0"
                v-model="new_person.nachname"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pl-0">
              <ggswitches
                :geimpft="false"
                :getestet="false"
                :genesen="false"
                :datum="false"
                @valid="validate_ggg_w_person($event)"
                @change="get_ggg_data_w_person($event)"
              ></ggswitches>
            </v-col>
          </v-row>
          <v-btn
            color="success"
            rounded
            :disabled="
              !new_person.vorname || !new_person.nachname || !ggg_valid_w_person
            "
            @click="
              weitere_person()
              new_person = {
                vorname: '',
                nachname: '',
                ggg: { getestet: false, geimpft: false, genesen: false },
              }
              add_person = false
            "
          >
            <v-icon>mdi-plus</v-icon>
            Hinzufügen
          </v-btn>
        </v-col>
      </v-expand-transition>
      <div v-if="user.data && !error">
        <v-col
          cols="12"
          class="my-5 text-center"
          v-if="
            user.data.rolle == 'Schiedsrichter' &&
            (selected_types.includes('Meisterschaftsspiel') ||
              selected_types.includes('Freundschaftsspiel') ||
              selected_types.includes('Pokalspiel'))
          "
        >
          <v-select
            :items="schiedsrichter_select"
            item-value="value"
            item-text="label"
            v-model="schiedsrichter"
            filled
            rounded
            label="Bist du als Schiedsrichter bei dieser Veranstaltung aktiv?"
          ></v-select>
        </v-col>
      </div>
      <v-col
        cols="12"
        class="my-5"
        v-if="
          zuschauerdaten &&
          (publicdata
            ? publicdata.verein
              ? publicdata.verein.hygienekonzept
                ? publicdata.verein.hygienekonzept.testpflicht ||
                  publicdata.verein.hygienekonzept.gg_regel ||
                  publicdata.verein.hygienekonzept.ggplus_regel
                  ? true
                  : false
                : false
              : false
            : false)
        "
      >
        <ggswitches
          :geimpft="false"
          :getestet="false"
          :genesen="false"
          :datum="false"
          :public="true"
          @valid="validate_ggg($event)"
          @change="get_ggg_data($event)"
        ></ggswitches>
      </v-col>
      <v-col cols="12" class="my-5 text-center" v-if="zuschauerdaten && !error">
        <v-btn
          :color="template.colors.primary"
          :style="`color: ${template.colors.inline_primary_text};`"
          block
          rounded
          :disabled="selection.length == 0 || testpflicht || add_person"
          x-large
          :loading="loading"
          @click="check_in()"
        >
          CHECK-IN
        </v-btn>
      </v-col>
      <v-col cols="12" class="my-5" v-if="error">
        <v-card
          :color="template.colors.primary"
          rounded="xl"
          class="pa-4 text-center"
        >
          <v-row justify="center">
            <v-col cols="10">
              <h2>ZUSCHAUER BEREITS ANWESEND</h2>
            </v-col>
            <v-col cols="10">
              <p>
                Es ist bereits ein Zuschauer mit deinen eingegebenen Daten bei
                dieser Veranstaltung anwesend. Daher war dein CheckIn nicht
                erfolgreich.
              </p>
            </v-col>
            <v-col cols="12">
              <v-divider class="my-3"></v-divider>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn
                rounded
                :to="
                  $route.params.sportstaette
                    ? '/' +
                      $route.params.verein +
                      '/' +
                      $route.params.sportstaette
                    : '/' + $route.params.verein
                "
              >
                ZURÜCK ZUR STARTSEITE
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-4" v-if="!publicdata.verein">
      <v-col
        cols="12"
        class="text-center mb-4"
        v-if="publicdata.nodata == false"
      >
        <h2>Verein wird geladen</h2>
        <v-progress-circular
          :color="template.colors.primary"
          :style="`color: ${template.colors.inline_primary_text};`"
          circle
          indeterminate
        ></v-progress-circular>
      </v-col>
      <v-col
        cols="12"
        class="text-center mb-4"
        v-if="publicdata.nodata == true"
      >
        <h2>Seite nicht gefunden</h2>
        <p>
          Dein eingegebener Link scheint fehlerhaft zu sein. Bitte überprüfe
          deine Eingabe und versuche es erneut.
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '../../store'
import router from '../../routes/index'
//import firebase from '../../firebaseConfig'
import ggswitches from './2G-Regel/switches.vue'

export default {
  name: 'Mein-Verein',
  components: {
    ggswitches,
  },
  data() {
    return {
      newzuschauer: false,
      selection: [],
      selected_types: [],
      valid: false,
      rolle: '',
      checkbox: false,
      zuschauerdaten: '',
      schiedsrichter: true,
      schiedsrichter_select: [
        {
          label: 'JA - Ich bin als Schiedsrichter bei diesem Spiel aktiv.',
          value: true,
        },
        {
          label: 'NEIN - Ich bin nur Zuschauer.',
          value: false,
        },
      ],
      v: {
        name: '',
        vorname: '',
        adresse: '',
        plz: '',
        ort: '',
        telefon: '',
        email: '',
        nation: 'Deutschland',
        aktivitaet: 'Zuschauer',
      },
      ggg: {
        getestet: false,
        geimpft: false,
        genesen: false,
      },
      ggg_valid: false,
      ggg_valid_w_person: false,
      aktivitaeten: [],
      weitere_personen: [],
      filter_veranstaltungen: [],
      new_person: {
        vorname: '',
        nachname: '',
        ggg: { getestet: false, geimpft: false, genesen: false },
      },
      add_person: false,
      loading: false,
      zutritt: '',
      timestamp: '',
      datum: '',
      endedatum: '',
      ankunftszeit: '',
      ankunftszeit_changed: false,
      aktuellezeit: '',
      modal2: '',
      error: false,
      errortext: '',
      zeiten: [
        {
          label: '15 Minuten',
          time: { hours: 0, minutes: 15 },
        },
        {
          label: '30 Minuten',
          time: { hours: 0, minutes: 30 },
        },
        {
          label: '1 Stunde',
          time: { hours: 1, minutes: 0 },
        },
        {
          label: '1.5 Stunden',
          time: { hours: 1, minutes: 30 },
        },
        {
          label: '2 Stunden',
          time: { hours: 2, minutes: 0 },
        },
        {
          label: '3 Stunden',
          time: { hours: 3, minutes: 0 },
        },
        {
          label: '4 Stunden',
          time: { hours: 4, minutes: 0 },
        },
        {
          label: '5 Stunden',
          time: { hours: 5, minutes: 0 },
        },
        {
          label: '6 Stunden',
          time: { hours: 6, minutes: 0 },
        },
        {
          label: '7 Stunden',
          time: { hours: 7, minutes: 0 },
        },
        {
          label: '8 Stunden',
          time: { hours: 8, minutes: 0 },
        },
        {
          label: '9 Stunden',
          time: { hours: 9, minutes: 0 },
        },
        {
          label: '10 Stunden',
          time: { hours: 10, minutes: 0 },
        },
        {
          label: '11 Stunden',
          time: { hours: 11, minutes: 0 },
        },
        {
          label: '12 Stunden',
          time: { hours: 12, minutes: 0 },
        },
      ],
      zeitwahl: {
        stunden: 0,
        minuten: 0,
      },
      rules: {
        required: (value) => !!value || 'Bitte gebe deine Daten ein',
        name: (v) =>
          v.length >= 2 ? true : 'Bitte gebe einen gültigen Namen ein',
        street: (v) =>
          v.length >= 3 ? true : 'Bitte gebe einen gültigen Straßennamen ein',
        streetnr: (v) =>
          v.match(/[0-9]{1,}/)
            ? true
            : 'Bitte gebe eine gültige Hausnummer ein',
        telefon: (v) =>
          v.match(/[0-9]{5,}/)
            ? true
            : 'Bitte gebe eine gültige Telefonnummer ein',
        plz: (v) =>
          v.match(/[0-9]{5}/)
            ? true
            : 'Bitte gebe eine gültige Postleitzahl ein',
      },
    }
  },
  computed: {
    ...mapGetters({
      publicdata: 'publicdata',
      user: 'user',
      template: 'template',
      timer: 'timer',
    }),
    testpflicht() {
      if (this.publicdata.verein) {
        if (this.publicdata.verein.hygienekonzept) {
          if (this.publicdata.verein.hygienekonzept.testpflicht) {
            if (!this.ggg.getestet && !this.ggg.geimpft && !this.ggg.genesen) {
              return true
            }
          }
          if (this.publicdata.verein.hygienekonzept.gg_regel) {
            if (!this.ggg.geimpft && !this.ggg.genesen) {
              return true
            }
          }
          if (this.publicdata.verein.hygienekonzept.ggplus_regel) {
            if (
              (!this.ggg.geimpft || !this.ggg.getestet) &&
              (!this.ggg.genesen || !this.ggg.getestet)
            ) {
              return true
            }
          }
        }
      }
      return false
    },
  },
  mounted() {
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        this.zuschauerdaten = ''
        if (this.$route.params.sportstaette) {
          router.push(
            '/' +
              this.$route.params.verein +
              '/' +
              this.$route.params.sportstaette,
          )
        } else {
          router.push('/' + this.$route.params.verein)
        }
      }
    })
    if (!this.publicdata.verein) {
      store.dispatch('setPublic', {
        name: this.$route.params.verein,
        sportstaette: this.$route.params.sportstaette,
      })
    } else {
      if (this.publicdata.verein.alias != this.$route.params.verein) {
        store.dispatch('setPublic', {
          name: this.$route.params.verein,
          sportstaette: this.$route.params.sportstaette,
        })
      }
    }
    if (this.user.data) {
      if (!this.user.zuschauerdaten) {
        if (this.$route.params.sportstaette) {
          router.push(
            '/' +
              this.$route.params.verein +
              '/p/' +
              this.$route.params.sportstaette +
              '/vervollständigen',
          )
        } else {
          router.push('/' + this.$route.params.verein + '/vervollstaendigen')
        }
      } else {
        this.zuschauerdaten = this.user.zuschauerdaten
        this.new_person.nachname = this.user.zuschauerdaten.name
      }
      if (this.user.data.rolle == 'Schiedsrichter') {
        this.rolle = 'Schiedsrichter'
      }
    }

    var today = new Date()
    this.zutritt = {
      minutes: parseInt(today.getMinutes()),
      hours: parseInt(today.getHours()),
    }
    this.timestamp = {
      minutes: parseInt(today.getMinutes()),
      hours: parseInt(today.getHours()),
    }
    this.aktuellezeit =
      this.addNull(this.timestamp.hours) +
      ':' +
      this.addNull(this.timestamp.minutes)
    this.ankunftszeit = this.aktuellezeit
    this.datum =
      today.getFullYear() +
      '-' +
      this.addNull(today.getMonth() + 1) +
      '-' +
      this.addNull(today.getDate())
    this.endedatum = this.datum

    if (this.standard_aktivitaeten) {
      if (
        this.publicdata.verein.aktivitaeten.length >
        this.template.var.standard_aktivitaeten.length
      ) {
        this.publicdata.verein.aktivitaeten.forEach((aktivitaet) => {
          if (
            !this.template.var.standard_aktivitaeten.includes(aktivitaet) ||
            this.template.var.public_aktivitaeten.includes(aktivitaet)
          ) {
            this.aktivitaeten.push(aktivitaet)
          }
        })
      }
    }
  },
  methods: {
    validate_ggg_w_person(daten) {
      this.ggg_valid_w_person = daten
    },
    get_ggg_data_w_person(daten) {
      if (daten) {
        this.new_person.ggg = {
          getestet: daten.ggg.getestet || false,
          geimpft: daten.ggg.geimpft || false,
          genesen: daten.ggg.genesen || false,
          geburtsdatum: daten.geburtsdatum || false,
        }
      }
    },
    validate_ggg(daten) {
      this.ggg_valid = daten
    },
    get_ggg_data(daten) {
      if (daten) {
        this.ggg = {
          getestet: daten.ggg.getestet || false,
          geimpft: daten.ggg.geimpft || false,
          genesen: daten.ggg.genesen || false,
          geburtsdatum: daten.geburtsdatum || false,
        }
      }
    },
    change_nation() {
      switch (this.v.nation) {
        case 'Deutschland':
          this.rules.plz = (v) =>
            v.match(/[0-9]{5}/)
              ? true
              : 'Bitte gebe eine gültige Postleitzahl ein'
          break
        case 'Belgien':
          this.rules.plz = (v) =>
            v.match(/[0-9]{4}/)
              ? true
              : 'Bitte gebe eine gültige Postleitzahl ein'
          break
        case 'Niederlande':
          this.rules.plz = (v) =>
            v.match(/[0-9]{4}/) && v.match(/[a-zA-Z]{2}/)
              ? true
              : 'Bitte gebe eine gültige Postleitzahl ein'
          break
        default:
          this.rules.plz = (v) =>
            v.match(/[0-9]{5}/)
              ? true
              : 'Bitte gebe eine gültige Postleitzahl ein'
          break
      }
    },
    filterveranstaltungen() {
      this.filter_veranstaltungen = []
      var alle_veranstaltungen = this.publicdata.veranstaltungen
      alle_veranstaltungen.forEach((v) => {
        if (v.ende.uhrzeit < this.ankunftszeit) {
          this.filter_veranstaltungen.push(v)
        }
      })
    },
    scrollTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    },
    weitere_person() {
      if (this.new_person) {
        this.weitere_personen.push(this.new_person)
      }
    },
    check_anzahlzuschauer() {
      firebase
        .firestore()
        .collection('User')
        .doc(this.publicdata.verein.id)
        .collection('Veranstaltung')
    },
    delete_person(index) {
      var i
      var hilf = this.weitere_personen
      this.weitere_personen = []
      for (i = 0; i < hilf.length; i++) {
        if (hilf[i] != hilf[index]) {
          this.weitere_personen.push(hilf[i])
        }
      }
    },
    select(id, art) {
      if (this.user) {
        if (this.selection.includes(id)) {
          this.remove(id, art)
        } else {
          this.selection.push(id)
          this.selected_types.push(art)
        }
      } else {
        if (this.selection.includes(id)) {
          this.selection = []
        } else {
          this.selection = []
          this.selection.push(id)
        }
      }
    },
    addNull(val) {
      if (val < 10) {
        return '0' + val
      } else {
        return val
      }
    },
    get_zutritt() {
      return (
        this.addNull(this.zutritt.hours) +
        ':' +
        this.addNull(this.zutritt.minutes)
      )
    },
    get_veranstaltung(id) {
      var i
      for (i = 0; i < this.publicdata.veranstaltungen.length; i++) {
        if (this.publicdata.veranstaltungen[i].id == id) {
          return this.publicdata.veranstaltungen[i]
        }
      }
      return {
        id: 'ANDERE',
        art: 'Andere',
        name: 'Andere Veranstaltung',
      }
    },
    calc_ende() {
      var datum = new Date()
      datum.setHours(datum.getHours() + this.zeitwahl.hours)
      datum.setMinutes(datum.getMinutes() + this.zeitwahl.minutes)
      var ende = {
        datum:
          datum.getFullYear() +
          '-' +
          this.addNull(datum.getMonth() + 1) +
          '-' +
          this.addNull(datum.getDate()),
        uhrzeit:
          this.addNull(datum.getHours()) +
          ':' +
          this.addNull(datum.getMinutes()) +
          ':' +
          this.addNull(datum.getSeconds()),
      }

      if (ende.datum > this.timer.datum) {
        ende = {
          datum: this.timer.datum,
          uhrzeit: '23:59:00',
        }
      }
      return new Zeit(ende.datum, ende.uhrzeit)
    },
    next_day() {
      var current = new Date(this.datum) //'Mar 11 2015' current.getTime() = 1426060964567
      var followingDay = new Date(current.getTime() + 86400000)
      this.endedatum =
        followingDay.getFullYear() +
        '-' +
        this.addNull(followingDay.getMonth() + 1) +
        '-' +
        this.addNull(followingDay.getDate())
    },
    change_zutritt(op) {
      if (op == '-') {
        this.zutritt.minutes--
        if (this.zutritt.minutes < 0) {
          this.zutritt.minutes = 59
          this.zutritt.hours--
          if (this.zutritt.hours < 0) {
            this.zutritt.hours = 0
            this.zutritt.minutes = 0
          }
        }
      }
      if (op == '+') {
        if (
          this.zutritt.minutes < this.timestamp.minutes ||
          this.zutritt.hours < this.timestamp.hours
        ) {
          this.zutritt.minutes++
          if (this.zutritt.minutes == 60) {
            this.zutritt.minutes = 0
            this.zutritt.hours++
            if (this.zutritt.hours == 24) {
              this.zutritt.hours = 23
              this.zutritt.minutes = 59
            }
          }
        }
      }
    },
    remove(id, art) {
      var i
      var new_selection = []
      var new_types_selection = []
      for (i = 0; i < this.selection.length; i++) {
        if (this.selection[i] != id) {
          new_selection.push(this.selection[i])
        }
      }
      var deleted = false
      for (i = 0; i < this.selected_types.length; i++) {
        if (this.selected_types[i] == art && !deleted) {
          deleted = true
        } else {
          new_types_selection.push(this.selected_types[i])
        }
      }
      this.selection = new_selection
      this.selected_types = new_types_selection
    },
    save_zuschauerdaten() {
      if (this.$refs.form.validate()) {
        this.zuschauerdaten = this.v
      }
    },
    async check_in() {
      this.loading = true
      var i = 0
      this.selection.forEach(async (v) => {
        var veranstaltung = this.get_veranstaltung(v)
        var verein = new Verein(
          this.publicdata.verein.id,
          this.publicdata.verein.verein.name,
          this.publicdata.verein.verein.logo,
          this.publicdata.verein.alias,
          this.publicdata.verein.hygienekonzept,
          this.publicdata.verein.verband,
        )
        var zutritt = this.ankunftszeit_changed
          ? new Zeit(this.timer.datum, this.ankunftszeit)
          : new Zeit('', '', Date.now())
        var verlassen = ''
        if (veranstaltung.art != 'Andere') {
          verlassen = new Zeit(
            veranstaltung.ende.datum,
            veranstaltung.ende.uhrzeit,
          )
        } else {
          verlassen = this.calc_ende()
        }

        var person = new Person(
          '',
          verein.getId(),
          this.zuschauerdaten.vorname,
          this.zuschauerdaten.name,
          this.zuschauerdaten.adresse,
          this.zuschauerdaten.plz,
          this.zuschauerdaten.ort,
          this.template.var.nationen ? this.zuschauerdaten.nation : '',
          this.zuschauerdaten.telefon,
          this.zuschauerdaten.email,
          zutritt,
          verlassen,
          veranstaltung,
          '',
          this.weitere_personen,
          this.publicdata.sportstaette,
          this.zuschauerdaten.aktivitaet
            ? this.zuschauerdaten.aktivitaet
            : 'Zuschauer',
          this.user.data ? this.user : '',
          verein,
          this.ggg ? this.ggg : '',
        )

        var checkIn = await person.check_in()
        if (checkIn.success) {
          i++
          if (i == this.selection.length) {
            if (this.user.data) {
              router.push('/user/tickets/' + checkIn.person.besuchId)
            } else {
              if (this.$route.params.sportstaette) {
                this.scrollTop()
                router.push(
                  '/' +
                    this.$route.params.verein +
                    '/' +
                    this.$route.params.sportstaette +
                    '/zuschauer/' +
                    checkIn.person.id,
                )
              } else {
                this.scrollTop()
                router.push(
                  '/' +
                    this.$route.params.verein +
                    '/zuschauer/' +
                    checkIn.person.id,
                )
              }
            }
          }
        }
      })
    },
    async old_check_in() {
      this.loading = true
      var i, j
      var endzeit = this.calc_ende()
      var daten = {
        person: {
          vorname: this.zuschauerdaten.vorname.trim(),
          name: this.zuschauerdaten.name.trim(),
          adresse: this.zuschauerdaten.adresse.trim(),
          plz: this.zuschauerdaten.plz.trim(),
          ort: this.zuschauerdaten.ort.trim(),
          telefon: this.zuschauerdaten.telefon.trim(),
        },
        zutritt: {
          datum: this.timer.datum,
          uhrzeit: this.ankunftszeit,
          timestamp: Date.now(),
        },
        weitere_personen: this.weitere_personen,
        sportstaette: this.publicdata.sportstaette,
      }
      if (this.template.var.nationen) {
        daten.person.nation = this.zuschauerdaten.nation
      }
      var veranstaltung = ''
      var verlassen = {
        datum: '',
        uhrzeit: '',
      }
      var aktivitaet = this.zuschauerdaten.aktivitaet
        ? this.zuschauerdaten.aktivitaet
        : 'Zuschauer'

      if (this.user) {
        for (i = 0; i < this.selection.length; i++) {
          veranstaltung = this.get_veranstaltung(this.selection[i])
          if (veranstaltung.art != 'Andere') {
            verlassen = veranstaltung.ende
          } else {
            verlassen = this.calc_ende()
          }

          if (this.user.data) {
            if (
              this.user.data.rolle == 'Schiedsrichter' &&
              this.schiedsrichter
            ) {
              aktivitaet = 'Schiedsrichter'
              daten.weitere_personen = []
            }
          }

          var person = {
            person: daten.person,
            veranstaltung: veranstaltung,
            zutritt: daten.zutritt,
            verlassen: verlassen,
            weitere_personen: daten.weitere_personen,
            aktivitaet: aktivitaet,
            sportstaette: daten.sportstaette,
          }
          let exists = false

          await firebase
            .firestore()
            .collection('User')
            .doc(this.publicdata.verein.id)
            .collection('Anwesenheit')
            .where('person.vorname', '==', person.person.vorname)
            .where('person.name', '==', person.person.name)
            .where('person.adresse', '==', person.person.adresse)
            .where('person.plz', '==', person.person.plz)
            .where('veranstaltung.id', '==', person.veranstaltung.id)
            .where('zutritt.datum', '==', person.zutritt.datum)
            .get()
            .then((snap) => {
              if (!snap.empty) {
                snap.forEach((doc) => {
                  var anw = doc.data()
                  if (
                    anw.verlassen.uhrzeit > person.zutritt.uhrzeit &&
                    anw.sportstaette.link == daten.sportstaette.link
                  ) {
                    exists = doc.data()
                    exists.id = doc.id
                  }
                })
              }
            })
            .catch((error) => {
              this.loading = false
            })
          if (exists) {
            if (this.user.data && exists.user) {
              if (this.user.uid == exists.user.id) {
                router.push('/user/tickets')
              } else {
                this.loading = false
                this.error = true
                this.errortext =
                  'Es ist bereits ein Zuschauer mit diesen Daten für die Veranstaltung eingecheckt.'
              }
            } else {
              this.loading = false
              this.error = true
              this.errortext =
                'Es ist bereits ein Zuschauer mit diesen Daten für die Veranstaltung eingecheckt.'
            }
          } else {
            await firebase
              .firestore()
              .collection('User')
              .doc(this.publicdata.verein.id)
              .collection('Anwesenheit')
              .add(person)
              .then((docRef) => {
                if (i + 1 == this.selection.length) {
                  this.scrollTop()
                  if (this.user.data) {
                    firebase
                      .firestore()
                      .collection('User')
                      .doc(this.user.uid)
                      .collection('Besuche')
                      .add({
                        person: daten.person,
                        anwesenheit: {
                          verein: this.publicdata.verein.id,
                          id: docRef.id,
                        },
                        verein: {
                          id: this.publicdata.verein.id,
                          name: this.publicdata.verein.verein.name,
                          logo: this.publicdata.verein.verein.logo,
                          alias: this.publicdata.verein.alias,
                          hygienekonzept: this.publicdata.verein.hygienekonzept
                            ? this.publicdata.verein.hygienekonzept
                            : '',
                          verband: this.publicdata.verein.verband,
                        },
                        veranstaltung: veranstaltung,
                        sportstaette: daten.sportstaette,
                        weitere_personen: this.weitere_personen,
                        zutritt: daten.zutritt,
                        verlassen: verlassen,
                        aktivitaet: aktivitaet,
                      })
                      .then((besuchRef) => {
                        firebase
                          .firestore()
                          .collection('User')
                          .doc(this.publicdata.verein.id)
                          .collection('Anwesenheit')
                          .doc(docRef.id)
                          .update({
                            user: {
                              id: this.user.uid,
                              anwesenheit: besuchRef.id,
                            },
                          })
                          .then(() => {
                            router.push('/user/tickets/' + besuchRef.id)
                          })
                          .catch((error) => {
                            this.loading = false
                          })
                      })
                      .catch((error) => {
                        console.log('Fehler', error)
                        this.loading = false
                      })
                  } else {
                    if (this.$route.params.sportstaette) {
                      this.scrollTop()
                      router.push(
                        '/' +
                          this.$route.params.verein +
                          '/' +
                          this.$route.params.sportstaette +
                          '/zuschauer/' +
                          docRef.id,
                      )
                    } else {
                      this.scrollTop()
                      router.push(
                        '/' +
                          this.$route.params.verein +
                          '/zuschauer/' +
                          docRef.id,
                      )
                    }
                  }
                }
              })
              .catch((error) => {
                this.loading = false
              })
          }
        }
      } else {
        this.loading = false
      }
    },
  },
}
</script>
